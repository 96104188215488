import React, { Fragment, useCallback, useEffect, useReducer, useRef } from 'react';
import { connect } from 'react-redux';

import { cn } from 'helpers/classnames';
import useMartyContext from 'hooks/useMartyContext';
import { getPDPTrackingPropsFormatted, priceToFloat } from 'helpers/ProductUtils';
import { SmallLoader } from 'components/Loader';
import { redirectTo } from 'actions/redirect';
import { fetchAllCollections, getListsForItemId, heartProduct, toggleHeartingLoginModal, unHeartProduct } from 'actions/hearts';
import { INFLUENCER_COLLECTION } from 'constants/influencerPages';
import { COLLECTION_TYPE } from 'constants/amethystEnums';
import { QUICKVIEW_PRODUCT_MODAL_PAGE } from 'constants/amethystPageTypes';
import InfluencerNewCollectionModal from 'components/influencer/collections/InfluencerNewCollectionModal';
import { withErrorBoundary } from 'components/common/MartyErrorBoundary';
import useEvent from 'hooks/useEvent';
import { getAbsoluteMarketplaceUrl, MERCHANTID_MARKET_NAME_MAPPING } from 'helpers/MarketplaceUtils';

import css from 'styles/components/influencer/collections/influencerCollectionsWidget.scss';

const OPEN = 'OPEN';
const SHOW_MORE = 'SHOW_MORE';
const SAVE_COLLECTION = 'SAVE_COLLECTION';
const ON_OPEN_MENU = 'ON_OPEN_MENU';
const SHOW_NEW_COLLECTION_MODAL = 'SHOW_NEW_COLLECTION_MODAL';
const IS_LOADING = 'IS_LOADING';
const NEW_COLLECTION_PAGE = '/influencer/hub/new/collection';

const initialState = {
  showMore: false,
  showNewCollectionModal: false,
  isLoading: false,
  hasOpened: false
};

export const reducer = (state, action) => {
  const { type, showMore, showNewCollectionModal, isLoading } = action;
  switch (type) {
    case OPEN:
      return { ...state, hasOpened: true };
    case SHOW_MORE:
      return { ...state, showMore };
    case SAVE_COLLECTION:
      return { ...state, showMore: true, showNewCollectionModal: false };
    case SHOW_NEW_COLLECTION_MODAL:
      return { ...state, showNewCollectionModal };
    case IS_LOADING:
      return { ...state, isLoading };
    case ON_OPEN_MENU:
      return { ...state, showMore: true, isLoading: true };
    default:
      return initialState;
  }
};

export const CreateNewCollection = ({ showMore, toggleNewCollectionModal, testId }) => (
  <button
    type="button"
    className={css.addNew}
    aria-expanded={showMore}
    onClick={toggleNewCollectionModal(true)}
    data-test-id={testId('addToNewCollection')}
  >
    Start a New Collection
  </button>
);

export const InfluencerCollectionsWidget = ({
  heartProduct,
  unHeartProduct,
  redirectTo,
  isCustomer,
  isQuickView = false,
  getStyleId,
  fetchAllCollections,
  getListsForItemId,
  hearts,
  colorId,
  productId,
  price,
  sourcePage,
  merchantId = undefined,
  isCollectionContentEmpty,
  url
}) => {
  const firstList = useRef();

  const [state, dispatch] = useReducer(reducer, initialState);

  const { showMore, showNewCollectionModal, isLoading, hasOpened } = state;

  const { host } = url;

  const itemId = getStyleId();
  const priceAsFloat = typeof price === 'string' ? priceToFloat(price) : price;

  const { collections, itemIdLists } = hearts;

  const {
    testId,
    marketplace: { merchantId: productMerchantId }
  } = useMartyContext();

  useEffect(() => {
    if (!showMore) {
      return;
    }

    const bindCloseEvent = e => {
      if (!e.target.closest(`[data-collection="InfluencerCollections-${itemId}"]`)) {
        dispatch({ type: SHOW_MORE, showMore: false });
      }
    };

    document.body.addEventListener('click', bindCloseEvent);
    return () => document.body.removeEventListener('click', bindCloseEvent);
  }, [itemId, showMore]);

  const onGetListsForItemId = listId => {
    getListsForItemId({ itemId }).then(() => {
      dispatch({ type: IS_LOADING, isLoading: false });
      if (listId) {
        document.getElementById(listId)?.focus();
      } else {
        firstList.current?.focus();
      }
    });
  };

  // Handler for escape keyboard key
  useEvent(document, 'keyup', keyboard => {
    if (showMore) {
      const key = keyboard.key || keyboard.keyCode;
      if (key === 'Escape' || key === 'Esc' || key === 27) {
        onCloseMenu();
      }
    }
  });

  const getHeartInfo = (listId = 'h.') => ({
    itemId,
    listId,
    listType: COLLECTION_TYPE.INFLUENCER_COLLECTION,
    colorId,
    productId,
    price: priceAsFloat,
    sourcePage: isQuickView ? QUICKVIEW_PRODUCT_MODAL_PAGE : sourcePage
  });

  const getListDetails = useCallback(() => {
    // fetch collections if not present or do not have metadata
    if ((!collections.length && !isCollectionContentEmpty) || (collections.length && !collections[0].metadata)) {
      fetchAllCollections({ overrideCollections: true }).then(onGetListsForItemId);
    } else if (collections.length) {
      onGetListsForItemId();
    }
  }, [collections, itemId]); // eslint-disable-line react-hooks/exhaustive-deps

  // checks if the user has hearted this product before on load
  useEffect(() => {
    if (isCustomer) {
      getListDetails();
    }
  }, [getListDetails, isCustomer]);

  const onCloseMenu = () => {
    dispatch({ type: SHOW_MORE, showMore: false });
  };

  const onOpenMenu = () => {
    if (!hasOpened) {
      dispatch({ type: OPEN });
    }
    getListDetails();
    dispatch({ type: ON_OPEN_MENU });
  };

  const onCollectionChange = (checked, id) => {
    dispatch({ type: IS_LOADING, isLoading: true });
    // `checked` comes back as the state it "will be" once its been clicked
    const heartType = checked ? unHeartProduct : heartProduct;
    let productProps = {
      ...getHeartInfo(id)
    };
    if (!checked) {
      productProps = {
        ...productProps,
        merchantId: merchantId
      };
    }
    heartType(productProps, () => {
      onGetListsForItemId(id);
    });
  };

  const onSaveNewCollection = () => {
    dispatch({ type: SAVE_COLLECTION });
    fetchAllCollections({ overrideCollections: true }).then(onGetListsForItemId);
  };

  const toggleNewCollectionModal = useCallback(
    showNewCollectionModal => () => {
      const baseUrl = getAbsoluteMarketplaceUrl('zappos', host);
      const marketPlaceName = MERCHANTID_MARKET_NAME_MAPPING[productMerchantId];
      if (showNewCollectionModal) {
        redirectTo(`${baseUrl}${NEW_COLLECTION_PAGE}?product=${itemId}_${marketPlaceName}`);
      }
    },
    [itemId, productMerchantId, redirectTo]
  );

  // Legacy settings
  const saveLabel = 'Add to Collections';

  let firstMatchingList; // returns first list in collections

  return (
    <>
      <div
        data-collection={'InfluencerCollections-' + itemId}
        className={cn(css.container, {
          [css.quickView]: isQuickView,
          [css.showMore]: showMore
        })}
      >
        <button
          type="button"
          aria-expanded={showMore}
          data-test-id={testId('addToCollections')}
          onClick={showMore ? onCloseMenu : onOpenMenu}
          className={cn(css.addToButton, isQuickView ? ' ' : showMore ? cn(css.addToButtonUpArrow, css.showMore) : css.addToButtonDownArrow)}
          {...getPDPTrackingPropsFormatted('Favorites', 'Button-Click')}
        >
          <span>{saveLabel}</span>
        </button>
        {showMore && (
          <div className={isQuickView ? css.listsQuickView : css.lists}>
            <div className={css.dropDownScrollBox}>
              {collections.length && itemIdLists && !isLoading ? (
                collections.map(({ listId, name, metadata }, index) => {
                  const { published } = metadata;
                  const matchingList = itemIdLists.find(itemIdList => listId === itemIdList.listId);
                  const url = matchingList ? window?.location.origin + `${INFLUENCER_COLLECTION}${matchingList.listId}` : '';
                  const collectionName = (
                    <>
                      {!published && <img alt="Hidden" className={css.hiddenEye} />}
                      {name}
                    </>
                  );

                  if (!firstMatchingList) {
                    firstMatchingList = matchingList;
                  }

                  let firstListRef;

                  switch (true) {
                    case firstMatchingList?.listId === listId: // if you have matching list, focus there first
                    case index === 0: // if no matching list, focus first available
                      firstListRef = firstList;
                      break;
                    default:
                      null;
                  }

                  if (isQuickView) {
                    return (
                      <Fragment key={listId}>
                        <input
                          id={`${listId}`}
                          ref={firstListRef}
                          onChange={() => {
                            onCollectionChange(matchingList, listId);
                          }}
                          defaultChecked={matchingList}
                          type="checkbox"
                        />
                        <label htmlFor={`${listId}`} data-test-id={testId(`collection_${name}`)}>
                          {name}
                        </label>
                      </Fragment>
                    );
                  }
                  return (
                    <div className={css.listItem} key={listId}>
                      <button
                        type="button"
                        onClick={() => {
                          onCollectionChange(matchingList, listId);
                        }}
                        onKeyPress={() => {
                          onCollectionChange(matchingList, listId);
                        }}
                        className={matchingList ? css.minusbutton : css.plusbutton}
                      />
                      {matchingList ? (
                        <>
                          <p>Added to&nbsp;</p>
                          <a href={url} title={name}>
                            {collectionName}
                          </a>
                        </>
                      ) : (
                        <p className={cn(css.listItemText, { [css.greyOutText]: !published })} title={name}>
                          {collectionName}
                        </p>
                      )}
                    </div>
                  );
                })
              ) : isCollectionContentEmpty ? (
                <></>
              ) : (
                <SmallLoader />
              )}
            </div>
            {!isQuickView && <CreateNewCollection showMore={showMore} toggleNewCollectionModal={toggleNewCollectionModal} testId={testId} />}
          </div>
        )}
      </div>
      <InfluencerNewCollectionModal
        showNewCollectionModal={showNewCollectionModal}
        onCancelModal={toggleNewCollectionModal(false)}
        onDoneModal={onSaveNewCollection}
      />
    </>
  );
};

export const mapStateToProps = state => ({
  isCustomer: !!state.cookies['x-main'],
  hearts: state.hearts,
  isCollectionContentEmpty: state.influencerContent.isCollectionContentEmpty,
  url: state.url
});

const ConnectedInfluencerCollectionsWidget = connect(mapStateToProps, {
  fetchAllCollections,
  getListsForItemId,
  heartProduct,
  unHeartProduct,
  toggleHeartingLoginModal,
  redirectTo
})(InfluencerCollectionsWidget);

export default withErrorBoundary('InfluencerCollectionsWidget', ConnectedInfluencerCollectionsWidget);
