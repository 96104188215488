import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';

import MelodyModal from 'components/common/MelodyModal';
import useMartyContext from 'hooks/useMartyContext';
import { createInfluencerCollection } from 'actions/hearts';
import { PROFILE_PAGE_REMINDER, REMINDER_MESSAGE_PROFILE } from 'constants/influencerMessages';

import css from 'styles/components/influencer/collections/influencerNewCollectionModal.scss';

export const InfluencerNewCollectionModal = ({ onDoneModal, showNewCollectionModal, onCancelModal, createInfluencerCollection }) => {
  // Save collection lifecycle controls
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  // Collection name ref
  const input = useRef();

  // Testing params
  const { testId } = useMartyContext();

  // Modal callback
  const onAfterOpen = () => {
    setHasError(false);
    input.current?.focus();
  };

  // Form submit handler
  const onSaveNewCollection = e => {
    e.preventDefault();
    const {
      collectionName: { value }
    } = e.target;

    // Resets the lifecycle controls
    setIsLoading(true);
    setHasError(false);

    // Attempt to save new collection
    createInfluencerCollection({ listName: value })
      .then(onDoneModal)
      .catch(() => setHasError(true)) // Failed
      .finally(() => setIsLoading(false)); // Lifecycle update
  };

  return (
    <MelodyModal className={css.modal} isOpen={showNewCollectionModal} onAfterOpen={onAfterOpen} onRequestClose={onCancelModal}>
      <div>
        <p className={css.header}>Create New Collection</p>
        <form method="post" onSubmit={onSaveNewCollection}>
          <div className={css.fields}>
            <p className={css.reminderMsg}>{`${REMINDER_MESSAGE_PROFILE} ${PROFILE_PAGE_REMINDER}`}</p>
            <input
              required
              ref={input}
              name="collectionName"
              id="collectionName"
              type="text"
              placeholder="Type collection name"
              data-test-id={testId('newCollectionNameInputBox')}
            />
            {hasError && (
              <div className={css.errorMsg} data-test-id={testId('newCollectionError')}>
                <p>Oh No! There was an issue creating your collection. Please try again.</p>
              </div>
            )}
          </div>

          <div className={css.footer}>
            <button type="button" onClick={onCancelModal} data-test-id={testId('cancelButton')}>
              Cancel
            </button>
            <button type="submit" disabled={isLoading} data-test-id={testId('createButton')}>
              Create
            </button>
          </div>
        </form>
      </div>
    </MelodyModal>
  );
};

const ConnectedInfluencerNewCollectionModal = connect(null, {
  createInfluencerCollection
})(InfluencerNewCollectionModal);

export default ConnectedInfluencerNewCollectionModal;
