import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';

import MelodyModal from 'components/common/MelodyModal';
import LinkShare from 'components/productdetail/sharing/LinkShare';
import FacebookShareV2 from 'components/productdetail/sharing/FacebookShareV2';
import PinterestShareV2 from 'components/productdetail/sharing/PinterestShareV2';
import TwitterShareV2 from 'components/productdetail/sharing/TwitterShareV2';
import useMartyContext from 'hooks/useMartyContext';
import UtilityStrokeCheckOutlineMediumIcon from 'tailwind/components/Icons/UtilityStrokeCheckOutlineMediumIcon';

import css from 'styles/components/influencer/influencerLinkShareModal.scss';

export const InfluencerLinkShareModal = ({
  isModalOpen,
  toggleVisibility,
  copyMessage,
  onSharingButtonClick,
  objectToShare,
  isLoadingFromVrsnl
}) => {
  const [isLinkCopiedToastActive, setLinkCopiedToastActive] = useState(false);

  const showLinkClicked = () => {
    setLinkCopiedToastActive(true);
    setTimeout(() => setLinkCopiedToastActive(false), 2000);
  };

  const makeLinkCopiedToast = () => (
    <div className={css.notificationBox}>
      <p className={css.textFrame}>
        <UtilityStrokeCheckOutlineMediumIcon className={css.checkMark} size={24} />
        {copyMessage}
      </p>
    </div>
  );

  const {
    testId,
    marketplace: {
      links: {
        sharing: { fbAppId }
      }
    }
  } = useMartyContext();
  const color = isLoadingFromVrsnl ? '#000' : '#005B94';

  const closeButtonRef = useRef();

  const afterOpenModal = () => {
    closeButtonRef.current?.focus();
  };

  return (
    <>
      {isLinkCopiedToastActive && ReactDOM.createPortal(makeLinkCopiedToast(), document.body)}
      <MelodyModal
        wrapperTestId={testId('influencerLinkShareModal')}
        className={css.modal}
        isOpen={isModalOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={toggleVisibility}
        ref={closeButtonRef}
      >
        <h1>Get Link and Share</h1>
        <div className={css.sharingIconsV2}>
          <section>
            <LinkShare
              className={css.link}
              product={objectToShare}
              iconFillColor={color}
              onClick={() => onSharingButtonClick('copy')}
              showLinkClicked={showLinkClicked}
              isLinkIconV2={true}
            />
            <p>Copy Link</p>
          </section>
          <section>
            <FacebookShareV2
              className={css.facebookV2}
              appId={fbAppId}
              product={objectToShare}
              iconFillColor={color}
              onClick={() => onSharingButtonClick('facebook')}
            />
            <p>Facebook</p>
          </section>
          <section>
            <PinterestShareV2
              className={css.pinterestV2}
              product={objectToShare}
              iconFillColor={color}
              onClick={() => onSharingButtonClick('pinterest')}
            />
            <p>Pinterest</p>
          </section>
          <section>
            <TwitterShareV2
              className={css.twitterV2}
              product={objectToShare}
              iconFillColor={color}
              onClick={() => onSharingButtonClick('twitter')}
            />
            <p>Twitter</p>
          </section>
        </div>
      </MelodyModal>
    </>
  );
};

export default InfluencerLinkShareModal;
